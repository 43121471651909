import "./App.css";
import Landing from "./components/Landing";
import CodePlayer from "./components/CodePlayer";
import CodePlayer_json from "./components/CodePlayer_json";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/replay" element={<CodePlayer />} />
        <Route path="/replay_json" element={<CodePlayer_json />} />

      </Routes>
    </Router>
  );
}

export default App;

