import React, { useState } from 'react';

const FlippingButton = (value) => {
  const [isRotated, setIsRotated] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  var icon;
  switch (value.command) {
    case "run":
      icon = "mdi mdi-play";
      break;
    case "save file":
      icon = "mdi mdi-content-save";
      break;
    case "save replay":
      icon = "mdi mdi-content-save-all";
      break;
    case "reset":
      icon = "mdi mdi-window-close";
      break;



  }

  const handleClick = () => {
    if (!isRotated) {
      setIsRotated(true);
      setTimeout(() => {
        setIsRotated(false);
        setShowCheck(true);
        setTimeout(() => {
          setShowCheck(false);
        }, 1500);
      }, 3000);

      value.onClick()

    }
  };

  return (
    <div className={`vertical flip-container ${isRotated ? 'rotate' : ''}`} onClick={handleClick}>
      <div className="flipper">
        <div
          className="front position-relative icon"
          style={{
            borderRadius: '50%',
            backgroundColor: '#2196F3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)',
            position: 'relative',
            margin: '10px'

          }}
        >
          {/* Tooltip */}
          <div className={`tooltip ${isRotated ? 'visible' : ''}`}>
            {value.command}

          </div>

          {/* Content */}
          {showCheck ? (
            <i className="mdi mdi-check" style={{ fontSize: '1.5em', color: '#fff' }}></i>
          ) : (
            <i className={icon} style={{ fontSize: '1.5em', color: '#fff' }}></i>
          )}
        </div>
        <div
          className="back"
          style={{
            boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)',
            borderRadius: '50%',
          }}
        >
          <div className="spinner">
            <div className="spinner-wrapper">
              <div className="rotator">
                <div className="inner-spin"></div>
                <div className="inner-spin"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlippingButton;
