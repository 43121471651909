import React from "react";
import Textarea from '@mui/joy/Textarea';

const OutputWindow = ({ outputDetails, getOutput }) => {

  // Determine the color based on statusId
  const getColor = () => {
    const statusId = outputDetails?.status?.id;
    return statusId === 6 || statusId === -11 ? "danger" : "neutral";
  };

  return (
    <>
      <Textarea
        color={getColor()}
        disabled={false}
        minRows={12}
        maxRows={12}
        placeholder="Input data"
        size="lg"
        variant="outlined"
        style={{ width: '100%', marginTop: '5px', backgroundColor: '#2b3945', color: '#fff' }}
        value={outputDetails || "Output data"}
      />
    </>
  );
};

export default OutputWindow;
