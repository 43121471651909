import React, { useEffect, useState } from 'react';

const Clock = ({ currentTime, date }) => {
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    useEffect(() => {
        const updateTime = () => {
            const [hour, minute, second] = currentTime.split(':');

            updateContainer('.hours', hour);
            updateContainer('.minutes', minute);
            updateContainer('.seconds', second);
        };

        const updateContainer = (containerClass, newTime) => {
            const container = document.querySelector(containerClass);
            const time = newTime.split('');

            if (time.length === 1) {
                time.unshift('0');
            }

            const first = container.firstElementChild;
            const last = container.lastElementChild;

            if (first.lastElementChild.textContent !== time[0]) {
                updateNumber(first, time[0]);
            }

            if (last.lastElementChild.textContent !== time[1]) {
                setTimeout(() => {
                    updateNumber(last, time[1]);
                }, 10); // Add a small delay to ensure a smoother transition
            }
        };

        const updateNumber = (element, number) => {
            const second = element.lastElementChild.cloneNode(true);
            second.textContent = number;

            element.appendChild(second);
            element.classList.add('move');

            setTimeout(() => {
                element.classList.remove('move');
                element.removeChild(element.firstElementChild);
            }, 990);
        };

        updateTime(); // Call it once to set the initial state

        return () => {
            // Cleanup (if needed) when the component unmounts
        };
    }, [currentTime]);

    return (
        <div className="clock">
            <div className="hours">
                <div className="first">
                    <div className="number">{hours.charAt(0)}</div>
                </div>
                <div className="second">
                    <div className="number">{hours.charAt(1)}</div>
                </div>
            </div>
            <div className="tick">:</div>
            <div className="minutes">
                <div className="first">
                    <div className="number">{minutes.charAt(0)}</div>
                </div>
                <div className="second">
                    <div className="number">{minutes.charAt(1)}</div>
                </div>
            </div>
            <div className="tick">:</div>
            <div className="seconds">
                <div className="first">
                    <div className="number">{seconds.charAt(0)}</div>
                </div>
                <div className="second infinite">
                    <div className="number">{seconds.charAt(1)}</div>
                </div>
            </div>
            <div className="date"> {date}</div>
        </div>
    );
};

export default Clock;
