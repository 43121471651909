import React, { useRef, useEffect, useState } from "react";

import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme, defaultValue, editorOptions }) => {
  const [value, setValue] = useState(code || "");
  const editorRef = useRef(null);
  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  useEffect(() => {
    if (editorRef.current) {
      // If the editor is already mounted, set the code programmatically
      editorRef.current.setValue(code);
      console.log("useEffect")
    }
  }, [code]);

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <Editor
        height="85vh"
        width={`100%`}
        language={language || "python"}
        value={code}
        theme={theme}
        defaultValue={defaultValue}
        onChange={handleEditorChange}
        options={editorOptions}
      />
    </div>
  );
};
export default CodeEditorWindow;
