import React, { useState, useCallback, useEffect } from 'react';
import JSZip from 'jszip';
import Editor from "@monaco-editor/react";
import Landing from './Landing';
import { languageOptions } from "../constants/languageOptions";
import Clock from './Clock';




const CodePlayer = () => {

    const defaultEntry = {
        filename: new Date().toISOString(),
        content: '#Upload your replay.zip',
    };

    const defaultIndexes = {
        console: 0,
        editor: 0,
        input: 0,
    }



    const [entries, setEntries] = useState([defaultEntry]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [sliderValue, setSliderValue] = useState(2);
    const [refreshInterval, setRefreshInterval] = useState(400 / sliderValue);
    const [time, setTime] = useState("00:00:00");
    const [date, setDate] = useState("2024-01-01");

    const [speedSliderValue, setSpeedSliderValue] = useState(2);
    const [fontSize, setFontSize] = useState(14); // Initial font size

    const [editorEntries, setEditorEntries] = useState(['#Upload your replay.zip']);
    const [consoleEntries, setConsoleEntries] = useState(['Output']);
    const [inputEntries, setInputEntries] = useState(['Input']);
    const [indexes, setIndexes] = useState([defaultIndexes]);

    const [currentEditorContent, setCurrentEditorContent] = useState('#Upload your replay.zip');
    const [currentConsoleContent, setCurrentConsoleContent] = useState('Output');
    const [currentInputContent, setCurrentInputContent] = useState('Input');


    function endsWithString(fileName, text) {
        const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
        return (fileNameWithoutExtension.endsWith(text))
    }

    const handleFileChange = useCallback(async (event, loadExample = false) => {
        try {
            let selectedFile;

            if (loadExample) {
                // Use your logic to determine the example.zip file path
                const exampleZipPath = '/example.zip';
                const response = await fetch(exampleZipPath);
                const blob = await response.blob();
                selectedFile = new File([blob], 'example.zip');
            } else {
                selectedFile = event.target.files[0];

                if (!selectedFile) {
                    alert('Please select a file.');
                    return;
                }
            }

            const zip = new JSZip();
            const zipFile = await zip.loadAsync(selectedFile);

            const fileNames = Object.keys(zipFile.files);
            const zipEntries = fileNames.map((fileName) => ({
                filename: fileName,
            }));

            var indexConsole = 0
            var indexEditor = 0
            var indexInput = 0

            setEditorEntries(['#Upload your replay.zip']);
            setConsoleEntries(['Output']);
            setInputEntries(['Input']);


            var first = true

            for (let entry of zipEntries) {
                const content = await zip.file(entry.filename).async('text');
                entry.content = content;
                if (endsWithString(entry.filename, "_console")) {
                    setConsoleEntries((prevList) => [...prevList, content]);
                    indexConsole = indexConsole + 1;

                }
                else if (endsWithString(entry.filename, "_input")) {
                    setInputEntries((prevList) => [...prevList, content]);
                    indexInput = indexInput + 1;
                }
                else {
                    setEditorEntries((prevList) => [...prevList, content]);
                    indexEditor = indexEditor + 1;
                }
                const value = {
                    console: indexConsole,
                    editor: indexEditor,
                    input: indexInput,
                }
                if (first) {
                    first = false
                    setIndexes([value]);
                }
                else {
                    setIndexes((prevList) => [...prevList, value]);
                }
            }
            console.log(consoleEntries)
            setEntries(zipEntries);
            setSliderValue(0);
            setCurrentIndex(0);

        } catch (error) {
            console.error('Error loading zip file:', error);
            alert('Error loading zip file. Please try again.');
            //window.location.reload();

        }
    }, []);

    const fetchExampleZip = async () => {
        try {
            // Trigger handleFileChange with the loadExample parameter set to true
            await handleFileChange(null, true);
        } catch (error) {
            console.error('Error loading example.zip:', error);
        }
    };
    useEffect(() => {
        // Call fetchExampleZip when the component is loaded
        fetchExampleZip();
    }, []);


    const increaseFontSize = () => {
        setFontSize((prevFontSize) => prevFontSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize((prevFontSize) => Math.max(8, prevFontSize - 2)); // Ensure the font size doesn't go below 8
    };
    const togglePause = () => {
        setIsPaused((prevIsPaused) => !prevIsPaused);
    };

    const resetReproduction = () => {
        setCurrentIndex(0);
        setSliderValue(0);
    };

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setSliderValue(value);
        setCurrentIndex(value);
    };

    const handleSpeedSliderChange = (event) => {
        const speedValue = parseInt(event.target.value, 10);
        const speedInterval = 400 / speedValue;
        setSpeedSliderValue(speedValue);
        setRefreshInterval(speedInterval);
    };

    useEffect(() => {
        //not showing the last console, not sure if it shows the last editor, and if it did before. Check witht the one deployed
        let intervalId;
        if (!isPaused) {
            intervalId = setInterval(() => {
                if (currentIndex < entries.length - 1) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    setSliderValue((prevIndex) => prevIndex + 1);

                }
            }, refreshInterval);
        }


        try {
            const dateObject = new Date(entries[currentIndex].filename.split((/[_.]/))[0]);
            const date = entries[currentIndex].filename.split("T")[0];

            console.log(date)

            setTime(dateObject.toTimeString().split(" ")[0]);
            setDate(date)
            setCurrentEditorContent(editorEntries[indexes[currentIndex].editor])
            setCurrentConsoleContent(consoleEntries[indexes[currentIndex].console]);
            setCurrentInputContent(inputEntries[indexes[currentIndex].input]);
        } catch (error) {
            console.log("changing file")
        }

        return () => clearInterval(intervalId);
    }, [currentIndex, entries, isPaused, refreshInterval]);

    const editorOptions = {
        readOnly: true,
        fontSize: fontSize,
    };



    return (

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw' }}>
            <input type="file" onChange={handleFileChange} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <button onClick={togglePause}>{isPaused ? 'Resume' : 'Pause'}</button>
                <button onClick={resetReproduction}>Reset</button>
                <label>Choose Step:</label>
                <input
                    type="range"
                    min="0"
                    max={entries.length - 1}
                    step="1"
                    value={sliderValue}
                    onChange={handleSliderChange}
                />
                <label>Adjust Speed:</label>
                <input
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={speedSliderValue}
                    onChange={handleSpeedSliderChange}
                />
                <span>Speed: {speedSliderValue}</span>
                <button onClick={increaseFontSize}>F+</button>
                <button onClick={decreaseFontSize}>F-</button>
                {date} {time}
            </div>
            <Landing
                code_parameter={currentEditorContent}
                replay={true}
                consoleOutput={currentConsoleContent}
                editorOptions={editorOptions}
                input={currentInputContent}
            />
        </div>

    );

};

export default CodePlayer;
