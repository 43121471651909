import React, { useState, useCallback, useEffect } from 'react';
import JSZip from 'jszip';
import Editor from "@monaco-editor/react";
import Landing from './Landing';

const CodePlayer_json = () => {
    const defaultEntry = {
        filename: new Date().toISOString(),
        content: '#Upload your replay.zip',
    };

    const defaultIndexes = {
        console: 0,
        editor: 0,
        input: 0,
    }



    const [entries, setEntries] = useState([defaultEntry]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [sliderValue, setSliderValue] = useState(2);
    const [refreshInterval, setRefreshInterval] = useState(400 / sliderValue);
    const [time, setTime] = useState("00:00:00");
    const [date, setDate] = useState("2024-01-01");

    const [speedSliderValue, setSpeedSliderValue] = useState(2);
    const [fontSize, setFontSize] = useState(14); // Initial font size

    const [editorEntries, setEditorEntries] = useState(['#Upload your replay.zip']);
    const [consoleEntries, setConsoleEntries] = useState(['Output']);
    const [inputEntries, setInputEntries] = useState(['Input']);
    const [indexes, setIndexes] = useState([defaultIndexes]);

    const [currentEditorContent, setCurrentEditorContent] = useState('#Upload your replay.zip');
    const [currentConsoleContent, setCurrentConsoleContent] = useState('Output');
    const [currentInputContent, setCurrentInputContent] = useState('Input');

    const [jsonData, setJsonData] = useState([]);

    const handleFileChange = useCallback(async (event) => {
        try {
            const selectedFile = event.target.files[0];
            if (!selectedFile) {
                alert('Please select a file.');
                return;
            }

            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const parsedData = JSON.parse(e.target.result);
                setCurrentEditorContent("");
                setJsonData(parsedData); // Update jsonData using setJsonData
                setCurrentIndex(0);
                setSliderValue(0);
            };
            fileReader.readAsText(selectedFile);
        } catch (error) {
            console.error('Error loading JSON file:', error);
            alert('Error loading JSON file. Please try again.');
        }
    }, []);

    useEffect(() => {
        let intervalId;
        if (!isPaused) {
            intervalId = setInterval(() => {
                if (currentIndex < jsonData.length - 1) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    setSliderValue((prevIndex) => prevIndex + 1);
                }
            }, refreshInterval);
            if (jsonData.length !== 0) {
                displayJson(currentIndex, currentIndex);
            }

        }

        // Update code content and other state based on currentIndex

        return () => clearInterval(intervalId);
    }, [currentIndex, isPaused, jsonData]);



    const handleSpeedSliderChange = (event) => {
        const speedValue = parseInt(event.target.value, 10);
        setSpeedSliderValue(speedValue);
        setRefreshInterval(400 / speedValue);
    };

    const editorOptions = {
        readOnly: true,
        fontSize: fontSize,
    };

    const fetchExampleZip = async () => {
        try {
            // Trigger handleFileChange with the loadExample parameter set to true
            await handleFileChange(null, true);
        } catch (error) {
            console.error('Error loading example.zip:', error);
        }
    };


    const increaseFontSize = () => {
        setFontSize((prevFontSize) => prevFontSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize((prevFontSize) => Math.max(8, prevFontSize - 2)); // Ensure the font size doesn't go below 8
    };
    const togglePause = () => {
        setIsPaused((prevIsPaused) => !prevIsPaused);
    };

    const resetReproduction = () => {
        setCurrentIndex(0);
        setSliderValue(0);
    };

    const displayJson = (start, end) => {
        let updatedContent;
        if (start == 0) {
            setCurrentEditorContent("");
            updatedContent = "";
        }
        else {
            updatedContent = currentEditorContent;
        }
        let i = start;
        while (i <= end) {
            let entry = jsonData[i];
            console.log("input " + entry.type)
            if (entry.type == "code") {
                entry.changes.forEach((change) => {
                    if (change.removed) {
                        // Remove characters from the current content
                        updatedContent = updatedContent.slice(0, change.oldStart) +
                            updatedContent.slice(change.oldStart + change.count);
                    } else {
                        // Insert characters into the current content
                        updatedContent = updatedContent.slice(0, change.newStart) +
                            change.value +
                            updatedContent.slice(change.newStart);
                    }
                });

                setCurrentEditorContent(updatedContent); // Update content once after applying all changes
            }
            else if (entry.type == "console") {
                setCurrentConsoleContent(entry.text)
            }
            else if (entry.type == "input") {
                console.log("input " + entry.text)
                setCurrentInputContent(entry.text)
            }
            i = i + 1;

        };

    }

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setSliderValue(value);
        displayJson(0, value);

    };
    const handleSliderRelease = (event) => {
        const value = parseInt(event.target.value, 10);
        setCurrentIndex(value);
    };


    function endsWithString(fileName, text) {
        const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
        return (fileNameWithoutExtension.endsWith(text))
    }


    return (

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw' }}>
            <input type="file" onChange={handleFileChange} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <button onClick={togglePause}>{isPaused ? 'Resume' : 'Pause'}</button>
                <button onClick={resetReproduction}>Reset</button>
                <label>Choose Step:</label>
                <input
                    type="range"
                    min="0"
                    max={jsonData.length - 1}
                    step="1"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    onMouseUp={handleSliderRelease}
                />
                <label>Adjust Speed:</label>
                <input
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={speedSliderValue}
                    onChange={handleSpeedSliderChange}
                />
                <span>Speed: {speedSliderValue}</span>
                <button onClick={increaseFontSize}>F+</button>
                <button onClick={decreaseFontSize}>F-</button>
                {date} {time}
            </div>
            <Landing
                code_parameter={currentEditorContent}
                replay={true}
                consoleOutput={currentConsoleContent}
                editorOptions={editorOptions}
                input={currentInputContent}
            />
        </div>

    );
};

export default CodePlayer_json;
