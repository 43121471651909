import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import SpinningButton from "./FlippingButton"
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import JSZip from "jszip";
import FlippingButton from "./FlippingButton";
import ResetConfirmationDialog from "./ResetConfirmationDialog";
import Textarea from '@mui/joy/Textarea';
import Cookies from 'js-cookie';

import * as Diff from 'diff';

let oldData = '';





const pythontDefault = `print("hello world")`;


const Landing = ({ code_parameter, consoleOutput = "output console", replay = false, editorOptions, input }) => {
  const [code, setCode] = useState(localStorage.getItem('savedCode') || pythontDefault);
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("Eiffel");
  const [language, setLanguage] = useState(languageOptions[0]);
  const [changesList, setChangesList] = useState(() => {
    const savedChangesList = localStorage.getItem('changesList');
    return savedChangesList ? JSON.parse(savedChangesList) : [];
  });
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [inputData, setInputData] = useState(localStorage.getItem('inputData') || "");
  const [executionCount, setExecutionCount] = useState(parseInt(localStorage.getItem('executionCount'), 10) || 0);

  console.log(code)

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");


  useEffect(() => {
    if (replay) {
      console.log("replay: " + consoleOutput);
      setCode(code_parameter);
      setOutputDetails(consoleOutput);
      setInputData(input);
    }
  }, [code_parameter, consoleOutput, input]);

  const handleReset = () => {
    setIsResetDialogOpen(true);
  };

  const handleResetConfirm = () => {
    // Perform reset logic here
    console.log('Reset confirmed!');
    oldData = ""
    setCode("")
    setOutputDetails("Output")
    setInputData("Input")
    setChangesList([])
    setExecutionCount(0)
    setIsResetDialogOpen(false);
  };

  const handleResetCancel = () => {
    setIsResetDialogOpen(false);
  };

  const handleEditorChange = (value) => {
    setCode(value)
    setChangesList((prevList) => [...prevList, value]);
  };

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (!replay) {
      console.log("saving")
      localStorage.setItem('inputData', inputData);
      localStorage.setItem('savedCode', code);
      localStorage.setItem('changesList', JSON.stringify(changesList));
      localStorage.setItem('executionCount', executionCount);
    }
  }, [code, changesList, executionCount, inputData]);

  const saveText = (type, text) => {
    const isoDate = new Date().toISOString();
    setChangesList((prevList) => [...prevList, { isoDate, type, text }]);
  }
  const handleCompile = () => {
    setExecutionCount((prevCount) => prevCount + 1);
    console.log("compile")
    setProcessing(true);
    setOutputDetails("Processing...");
    const formData = {
      language_id: language.id,
      source_code: window.btoa(unescape(encodeURIComponent(code))),
      stdin: window.btoa(unescape(encodeURIComponent(inputData))),
    };

    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        "X-Auth-Token": process.env.REACT_APP_JUDGE_KEY
      },
      data: formData,
    };

    // Create a promise that resolves when the request is successful
    const requestPromise = axios.request(options);

    // Create a promise that rejects after 10 seconds
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000); // 20 seconds
    });

    // Use Promise.race to wait for either the request or the timeout
    Promise.race([requestPromise, timeoutPromise])
      .then(function (response) {
        // Handle the response here
        console.log("res.data", response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        // Handle timeout or other errors here
        let data;
        if (err.message === "Request timed out") {
          data = `Connection Failed`
          console.log("Request timed out");
          // Handle timeout error
        } else {
          let error = err.response ? err.response.data : err;
          console.log("catch block...", error);
          let status = err.response.status;
          console.log("status", status);
          if (status === 429) {
            data = "Quota of requests exceeded for the Day"
          } else if (status === 422) {
            data = "Empty code"
          } else {
            console.log(status)
            data = `Code server error`
          }
          const type = "console";
          saveText(type, data);
        }
        setProcessing(false);
        setOutputDetails(data);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        "X-Auth-Token": process.env.REACT_APP_JUDGE_KEY
      },
    };

    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        console.log("proccesing")
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        const responseText = getOutput(response.data)
        setOutputDetails(responseText);
        console.log("outputdetails" + response.data)
        showSuccessToast(`Compiled Successfully!`);

        const type = "console";
        const text = getOutput(response.data);
        saveText(type, text);


        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  const getOutput = (outputDetails) => {
    let statusId = outputDetails?.status?.id;

    const decodeBase64 = (encodedString) => {
      return encodedString ? decodeURIComponent(escape(atob(encodedString))) : null;
    };

    if (statusId === 6) {
      // Compilation error
      console.log("details: " + decodeBase64(outputDetails?.compile_output));
      return decodeBase64(outputDetails?.compile_output);
    } else if (statusId === 3) {
      // Successful execution
      return outputDetails.stdout !== null
        ? `${decodeBase64(outputDetails.stdout)}`
        : "Execution without output";
    } else if (statusId === 5) {
      // Time Limit Exceeded
      return "Time Limit Exceeded";
    } else if (statusId === -11) {
      // Time Limit Exceeded
      return "Connection Failed";
    } else {
      // Other cases
      return decodeBase64(outputDetails.stdout) + decodeBase64(outputDetails?.stderr);
    }
  };


  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        const isoDate = new Date().toISOString();
        console.log(isoDate)

        const diff = Diff.diffChars(oldData, data);
        const changes = diff.map((part, index) => {
          if (part.added || part.removed) {
            return {
              ...part,
              oldStart: diff.slice(0, index).reduce((sum, { count = 0 }) => sum + count, 0),
              newStart: diff.slice(0, index).reduce((sum, { count = 0 }) => sum + count, 0) + (part.removed ? part.count : 0),
            };
          }
          return part;
        }).filter(part => part.added || part.removed);
        setCode(data);
        let type = "code"
        setChangesList((prevList) => [...prevList, { isoDate, type, changes }]);
        console.warn("added", action, changes);
        console.log(changesList);

        oldData = data;
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  }


  function handleSaveChanges() {
    console.log(changesList);
    // Convert the changesList to a JSON string
    const dataStr = JSON.stringify(changesList);

    // Create a blob object representing the data as bytes
    const blob = new Blob([dataStr], { type: "application/json" });

    // Create a link element
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'replay.json';

    // This will download the data file named "changesList.json"
    link.click();
  }



  const handleSaveFile = async () => {

    // Create a Blob with the text content
    const blob = new Blob([code], { type: 'text/plain' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'last_file.py';

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link element after download
    document.body.removeChild(link);

  };

  const handleInputChange = (e) => {
    const newInputData = e.target.value;

    setInputData(newInputData);

    const type = "input";
    const text = newInputData;
    saveText(type, text);

  };



  return (

    <>

      <div className="flex flex-row space-x-4 items-start px-4 py-4">
        <div className="flex flex-col w-full h-full justify-start items-end">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language="python"
            theme="eiffel"
            defaultValue={pythontDefault}
            editorOptions={editorOptions}
          />
        </div>


        <div className="right-container flex flex-shrink-0 w-[30%] flex-col">
          <OutputWindow outputDetails={outputDetails} />
          <div className="flex flex-col items-end">
            <Textarea
              color="neutral"
              disabled={false}
              minRows={6}
              maxRows={6}
              placeholder="Input data"
              size="lg"
              variant="soft"
              style={{ width: '100%', marginTop: '5px' }}
              value={inputData}
              onChange={handleInputChange}
            />


            {!replay ? (
              <>
                <ul className="wrapper">
                  <li className="flipping-button-list-item">
                    <FlippingButton command="run" onClick={handleCompile} />
                  </li>
                  <li className="spacer"></li>
                  <li className="flipping-button-list-item">
                    <FlippingButton command="save file" onClick={handleSaveFile} />
                  </li>
                  <li className="spacer"></li>
                  <li className="flipping-button-list-item">
                    <FlippingButton command="save replay" onClick={handleSaveChanges} />
                  </li>
                  <li className="spacer"></li>
                  <li className="flipping-button-list-item">
                    <FlippingButton command="reset" onClick={handleReset} />
                  </li>
                </ul>
                <p>
                  Executions: {executionCount} Modifications: {changesList.length}
                </p>
              </>
            ) : null}


          </div>
        </div>

      </div >

      {/* Reset confirmation dialog component */}
      <ResetConfirmationDialog
        open={isResetDialogOpen}
        onClose={handleResetCancel}
        onConfirm={handleResetConfirm}
      />


    </>
  );
};
export default Landing;
